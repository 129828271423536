// Standard lib
import React, {
  FunctionComponent,
  useState,
} from 'react'
import {
  Button,
  Box,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Center,
  Checkbox,
  ModalFooter
} from '@chakra-ui/react'
import { Type_GenericApplicationData } from '../../types/Types'
import { OSTooltip } from '../../types/Utils'


type FCType_ModalDocumentation = {
  app_data: Type_GenericApplicationData
}

/**
 * Modal containing documentation
 *
 * @param {*} { new_data, additionalMenu, Reinitialization }
 * @return {*}
 */
export const ModalDocumentation: FunctionComponent<FCType_ModalDocumentation> = (
  {
    app_data
  }
) => {
  // Data -------------------------------------------------------------------------------
  const { never_see_again, show_splashscreen } = app_data.menu_configuration

  // Component updater ------------------------------------------------------------------
  const [, setCount] = useState(0)
  app_data.menu_configuration.ref_to_splashscreen_updater.current = () => setCount(a => a + 1)

  // Component --------------------------------------------------------------------------
  return <Modal
    isOpen={show_splashscreen}
    onClose={() => null}
    scrollBehavior='inside'
    variant='modal_documentation'
  >
    <ModalOverlay />
    <ModalContent>
      <ModalBody >
        <Center>
          <Box layerStyle='menuconfigpanel_grid'>
            <Box
              layerStyle='options_2cols'
              height='fit-content'
              gridColumnGap='0.5rem'
            >
              <OSTooltip
                placement='left-end'
                label={app_data.t('tooltip.start')}
                isAlwaysOpen={show_splashscreen}>
                <Button
                  variant='btn_documentation'
                  onClick={() => {
                    app_data.menu_configuration.dict_setter_show_dialog.ref_setter_show_modal_templates_lib.current!(true)
                    app_data.menu_configuration.show_splashscreen = false
                  }}>
                  {app_data.t('start')}
                </Button>
              </OSTooltip>
              <OSTooltip
                placement='right-end'
                label={app_data.t('tooltip.diaporama')}
                isAlwaysOpen={show_splashscreen}>
                <Button
                  variant='btn_documentation'
                  onClick={() => {
                    app_data.menu_configuration.dict_setter_show_dialog.ref_setter_show_modal_welcome.current!(true)
                    app_data.menu_configuration.show_splashscreen = false
                  }}>
                  {app_data.t('diaporama')}
                </Button>
              </OSTooltip>
            </Box>
          </Box>
        </Center>
      </ModalBody>
      <ModalFooter>
        <Checkbox
          variant='checkbox_dont_show_again'
          isChecked={never_see_again.current}
          onChange={evt => {
            never_see_again.current = evt.target.checked
            localStorage.setItem('dontSeeAgainWelcome', evt.target.checked ? '1' : '0')
            app_data.menu_configuration.show_splashscreen = !evt.target.checked
          }}
        >
          <OSTooltip
            placement='bottom'
            label={app_data.t('tooltip.dontSeeAgain')}>
            {app_data.t('dontSeeAgain')}
          </OSTooltip>
        </Checkbox>
      </ModalFooter>
    </ModalContent>
  </Modal>
}

