// ==================================================================================================
// Author : Vincent LE DOZE & Vincent CLAVEL for TerriFlux
// Date : 29/05/2024
// All rights reserved for TerriFlux
// ==================================================================================================

// External imports
import * as d3 from 'd3'

// Local types imports
import type {
  Class_MenuConfig
} from '../types/MenuConfig'
import type {
  ClassAbstract_DrawingArea,
  ClassAbstract_Sankey
} from '../types/Abstract'

// Local modules imports
import {
  ClassTemplate_Element,
  ClassTemplate_ProtoElement
} from '../Elements/Element'
import { Type_ElementPosition } from '../types/Utils'
import { default_element_position } from '../types/Utils'

// CLASS HANDLER ************************************************************************

/**
 * Class that define a handler used to manipulate a element
 * @export
 * @class ClassTemplate_Handler
 * @extends {ClassTemplate_Element}
 */
export class ClassTemplate_Handler
  <
    Type_GenericDrawingArea extends ClassAbstract_DrawingArea,
    Type_GenericSankey extends ClassAbstract_Sankey
  >
  extends ClassTemplate_Element
  <
    Type_GenericDrawingArea,
    Type_GenericSankey
  > {

  // PROTECTED ATTRIBUTES ===============================================================

  protected _display: {
    drawing_area: Type_GenericDrawingArea,
    sankey: Type_GenericSankey,
    position: Type_ElementPosition,
  }

  // PRIVATE ATTRIBUTES =================================================================

  private _size: number = 5
  private _color: string = 'black'
  private _filled: boolean = true
  private _custom_class: string | undefined
  private _ref_element: ClassTemplate_ProtoElement<Type_GenericDrawingArea, Type_GenericSankey>
  private _ref_element_optional?: ClassTemplate_ProtoElement<Type_GenericDrawingArea, Type_GenericSankey> | undefined
  private _custom_html_grp: boolean

  // CONSTRUCTOR ========================================================================

  /**
  * Creates an instance of ClassTemplate_Handler.
  * @param {string} id
  * @param {Type_GenericDrawingArea} drawing_area
  * @param {Class_MenuConfig} menu_config
  * @param {ClassTemplate_ProtoElement} ref
  * @param {(event: d3.D3DragEvent<SVGGElement, unknown, unknown>) => void} dragStart_function
  * @param {(event: d3.D3DragEvent<SVGGElement, unknown, unknown>) => void} drag_function
  * @param {(event: d3.D3DragEvent<SVGGElement, unknown, unknown>) => void} dragEnd_function
  * @param {{class?:string, size?: number, color?: string, filled?: boolean }} [options]
  * @param {ClassTemplate_ProtoElement} [ref_optional]
  * @memberof ClassTemplate_Handler
  */
  constructor(
    id: string,
    drawing_area: Type_GenericDrawingArea,
    menu_config: Class_MenuConfig,
    ref: ClassTemplate_ProtoElement<Type_GenericDrawingArea, Type_GenericSankey>,
    dragStart_function: (event: d3.D3DragEvent<SVGGElement, unknown, unknown>) => void,
    drag_function: (event: d3.D3DragEvent<SVGGElement, unknown, unknown>) => void,
    dragEnd_function: (event: d3.D3DragEvent<SVGGElement, unknown, unknown>) => void,
    options?: { class?: string, size?: number, color?: string, filled?: boolean },
    ref_optional?: ClassTemplate_ProtoElement<Type_GenericDrawingArea, Type_GenericSankey>,
    custom_parent_grp?: string
  ) {
    // Init parent class attributes
    super(id, menu_config, custom_parent_grp ? custom_parent_grp : 'g_handlers')
    this._ref_element = ref
    this._ref_element_optional = ref_optional
    this._custom_html_grp = custom_parent_grp !== undefined
    // Init other class attributes
    this._display = {
      drawing_area: drawing_area,
      sankey: drawing_area.sankey as Type_GenericSankey,
      position: structuredClone(default_element_position),
    }
    // Drag handling functions -> defined by parent element
    this.eventMouseDragStart = dragStart_function
    this.eventMouseDrag = drag_function
    this.eventMouseDragEnd = dragEnd_function
    // Set optional variable value
    if (options) {
      if (options.size !== undefined) {
        this._size = options.size
      }
      if (options.color !== undefined) {
        this._color = options.color
      }
      if (options.filled !== undefined) {
        this._filled = options.filled
      }
      if (options.class !== undefined) {
        this._custom_class = options.class
      }
    }
  }

  // COPY METHODS =======================================================================

  protected _copyFrom(element: ClassTemplate_Handler<Type_GenericDrawingArea, Type_GenericSankey>) {
    super._copyFrom(element)
    this._size = element._size
    this._color = element._color
    this._filled = element._filled
    this._custom_class = element._custom_class
  }

  // PUBLIC METHODS =====================================================================

  public drawElements() {
    this._process_or_bypass(() => this._drawElement())
  }

  protected _drawElement() {
    this.d3_selection?.attr('class', 'gg_handler')
    if (this._custom_class !== undefined) {
      this.d3_selection?.attr('class', this._custom_class)
    }
    this.d3_selection?.append('rect')
      .attr('x', -this._size / 2)
      .attr('y', -this._size / 2)
      .attr('width', this._size)
      .attr('height', this._size)
      .attr('stroke', this._color)
      .attr('stroke-width', 1)
      .attr('fill', this._color)
      .attr('fill-opacity', this._filled ? 1 : 0)
  }


  // PROTECTED METHODS =====================================================================

  protected _draw() {
    super._draw()
    this._drawElement()
  }


  /**
   * Override initDraw to allow the creation of html grp outside the DA
   *
   * @memberof Class_Handler
   */
  protected override _initDraw(): void {
    // If the parent id is referenced in the constructor we allow the creation of the new group outside the DA
    // (orginally this override was created to allow the creation of legend handler outside the DA)
    if (this._custom_html_grp) {
      const d3_svg = this.drawing_area.d3_selection_zoom_area
      if (d3_svg !== null) {
        const d3_drawing_area_selection = d3_svg.selectAll(' #' + this._svg_parent_group)
        if (d3_drawing_area_selection.nodes().length > 0) {
          this.d3_selection = d3_drawing_area_selection.append('g')
          this.d3_selection.attr('id', this.svg_group)
            .attr('transform', 'translate(' + 0 + ',' + this.drawing_area.getNavBarHeight() + ')') // init drawing area zone with a margin for taking into account the navbar
        }
      }
    } else {
      // Normal _initDraw
      super._initDraw()
    }
  }

  // GETTERS / SETTERS ==================================================================

  /**
     * Getter used to display or not the handler (called in draw of ClassTemplate_Element)
     *
     * @readonly
     * @memberof ClassTemplate_Handler
     */
  public get is_visible(): boolean {
    return (
      super.is_visible &&
      this._ref_element.is_visible &&
      this._ref_element.is_selected &&
      (this._ref_element_optional?.is_visible ?? true))
  }

  public get ref_element(): ClassTemplate_ProtoElement<Type_GenericDrawingArea, Type_GenericSankey> {
    return this._ref_element
  }

  public get ref_element_optional(): ClassTemplate_ProtoElement<Type_GenericDrawingArea, Type_GenericSankey> | undefined {
    return this._ref_element_optional
  }
}